@media (max-width: 600px) {
  .responsive-sider {
    position: absolute !important;
    z-index: 10  !important;
    width: 100% !important;
    max-width: none !important;
  }
  .character-xp-to-spend-container, .character-xp-spent-container {
    display: none;
  }

  .display-none{
    display: none;
  }

  div.div-display{
    display: block;
  }
  div.div-display-inline{
    display: inline-block;
  }
}
.anticon-menu {
  min-width: 20px;
  min-height: 20px;
  font-size: 25px;
  padding: 8px;
}

@media (min-width: 601px) {
  .anticon-menu {
    display: none;
  }

}