.inner-red  > .ant-checkbox > .ant-checkbox-inner{
    background-color: #DC2F18;
}

.inner-yellow  > .ant-checkbox > .ant-checkbox-inner{
    background-color: #EE6A0A;
}

.inner-green  > .ant-checkbox > .ant-checkbox-inner{
    background-color: #18AB0B;
}

button.ant-btn {
    padding: 5px;
}