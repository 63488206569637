.ant-card {
    text-align: left;
}

.ant-card-small > .ant-card-head , .ant-card-small > .ant-card-body  {
    padding: 3px;
}

.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
    padding: 0;
}

.ant-card-small > .ant-card-head{
    min-height: 0;
}

.ant-card-bordered {
    border-radius: 7px
}

.weapon-card {
    max-width: 450px;
    min-width: 380px;
    margin: 8px;
}
.skills-card {
    max-width: 350px;
    min-width: 350px;
    margin: 8px;
}

.talents-card{
    max-width: 350px;
    min-width: 350px;
    margin: 8px;
}

.flex-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}
.item-card{
    max-width: 450px;
    min-width: 380px;
    margin: 4px;
}
.card-col-input-input{
    max-width: 59px;
}