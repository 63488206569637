.characteristics-sml-container{
    display: flex;
    flex-flow: row wrap;
}

.characteristics-sml-element{
    display: block;
    margin: 2px 8px;
    width: 110px;
    text-align: left;
}