.talents-table {
    width: 700px;
}

.talent-green {
    background-color: #008000a3;
}

.talent-orange {
    background-color: #ffa500a6;
}

.talent-red {
    background-color: #c94729bf;
}

.ant-collapse-header {
    padding: 5px!important;
}